import { TRPCClientError } from '@trpc/client'
import type { inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@/server/trpc/routers'

type RouterOutput = inferRouterOutputs<AppRouter>
type sitesByTypes = RouterOutput['sites']['sitesByTypes']

type ErrorOutput = TRPCClientError<AppRouter>

let cachedData: sitesByTypes | null = null

export default function useGetSitesByTypes(options: { page?: number, pageSize?: number }) {
  const { page = 1, pageSize = 10 } = options
  const { $client } = useNuxtApp()

  const fetchData = async () => {
    if (cachedData) {
      return cachedData
    }
    cachedData = await $client.sites.sitesByTypes.query({
      page,
      pageSize
    })
    return cachedData
  }

  return useAsyncData<sitesByTypes, ErrorOutput>('getSitesByTypes', fetchData)
}
