import { TRPCClientError } from '@trpc/client'
import type { inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@/server/trpc/routers'

type RouterOutput = inferRouterOutputs<AppRouter>
type sitesByCategories = RouterOutput['sites']['sitesByCategories']

type ErrorOutput = TRPCClientError<AppRouter>

let cachedData: sitesByCategories | null = null

export default function useGetSitesByCategories(options: { page?: number, pageSize?: number }) {
  const { page = 1, pageSize = 10 } = options
  const { $client } = useNuxtApp()

  const fetchData = async () => {
    if (cachedData) {
      return cachedData
    }
    cachedData = await $client.sites.sitesByCategories.query({
      page,
      pageSize
    })
    return cachedData
  }

  return useAsyncData<sitesByCategories, ErrorOutput>('getSitesByCategories', fetchData)
}
