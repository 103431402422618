<template>
  <div
    v-if="sites.length"
    class="p-8 space-y-3 rounded-xl gradient-border"
  >
    <div class="inline-flex items-center">
      <div class="gradient-icon p-2 rounded-lg">
        <svg
          class="w-8 h-8 text-white"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
      </div>
      <p class="justify-center leading-loose ml-2 gradient-text font-semibold">
        {{ translatedLabel }}
      </p>
    </div>

    <NuxtLink
      v-for="site in sites"
      :key="site.site.id"
      :to="{
        name: 'websites-nouvelle-adresse-ref-2024',
        params: {
          ref: site.site.ref,
        },
      }"
      class="block"
    >
      <div class="dark:bg-gray-900 dark:text-gray-300 transform bg-white border-l-4 border-gradient hover:-translate-y-2">
        <div class="h-full p-5 border border-l-0 rounded-r shadow-sm">
          <h6 class="mb-2 font-semibold leading-5">
            <span>{{ site.site.name }}</span>
          </h6>
        </div>
      </div>
    </NuxtLink>

    <NuxtLink
      :to="{
        name: 'liste-des-sites-de-category',
        params: {
          category: label,
        },
      }"
      @click="handleNavigation"
      class="inline-flex p-2 gradient-text capitalize transition-colors duration-300 transform gradient-bg rounded-full rtl:-scale-x-100 dark:text-gray-300 hover:opacity-90"
    >
      <svg
        class="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </svg>
      <p>{{ $t("en_savoir_plus") }}</p>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const router = useRouter();
const { t } = useI18n();

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  sites: {
    type: Array as () => Object[],
    default: () => [],
  },
});

const translatedLabel = computed(() => t(props.label));

const handleNavigation = async (e: Event) => {
  e.preventDefault();
  if (props.label) {
    try {
      await router.push({
        name: "liste-des-sites-de-category",
        params: {
          category: props.label,
        },
      });
    } catch (error) {
      console.error("Navigation error:", error);
    }
  }
};
</script>

<style scoped>
.gradient-border {
  position: relative;
  border: 2px solid transparent;
  border-radius: 0.75rem;
  background-clip: padding-box;
}

.gradient-border::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 0.75rem;
  padding: 2px;
  background: var(--gradient);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.gradient-icon {
  background: var(--gradient);
}

.gradient-text {
  background: var(--gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.gradient-bg {
  background: var(--gradient);
  color: white;
}

.gradient-bg:hover {
  opacity: 0.9;
}

/* Pour la bordure gauche des éléments de la liste */
.border-l-4.border-gradient {
  border-left: 4px solid;
  border-image: var(--gradient) 1;
}
</style>
