<template>
  <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-2 mx-auto">
      <div
        class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3"
      >
        <CategoryBox
          v-for="(type, index) in sitesByTypes?.types"
          :key="`type-${type.privateLabel}-${index}`"
          :label="type.privateLabel"
          :sites="type?.sites"
        />
        <CategoryBox
          v-for="(category, index) in sitesByCategories?.categories"
          :key="`category-${category.privateLabel}-${index}`"
          :label="category.privateLabel"
          :sites="category?.sites"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
const { data: sitesByTypes } = useGetSitesByTypes({});
const { data: sitesByCategories } = useGetSitesByCategories({});
</script>

<style>
:root {
  --gradient-start: #8B5CF6;
  --gradient-end: #3B82F6;
  --gradient: linear-gradient(135deg, var(--gradient-start), var(--gradient-end));
}
</style>
